<script setup lang="ts">
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { __ } from '@@/bits/intl'
import { NATIVE_HOST } from '@@/bits/url'
import { OzBaseButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
import { computed } from 'vue'
import type { StyleValue } from 'vue/types/jsx'

const props = withDefaults(
  defineProps<{
    /**
     * Dark mode.
     */
    darkMode?: 'auto' | boolean

    /**
     * URL where the logo points to.
     */
    href?: string

    /**
     * Color scheme.
     */
    colorScheme?: ColorScheme

    /**
     * Size preset.
     */
    sizePreset?: SizePreset

    /**
     * See `OzBaseButton`.
     */
    hrefMode?: OzBaseButtonHrefMode

    /**
     * Determines whether to show the crane icon.
     */
    xCraneIcon?: boolean

    /**
     * Style to apply to svg element
     */
    svgStyle?: StyleValue
  }>(),
  {
    darkMode: 'auto',
    href: `https://${NATIVE_HOST}`,
    colorScheme: ColorScheme.Text100,
    sizePreset: SizePreset.W64px,
    hrefMode: OzBaseButtonHrefMode.Default,
    xCraneIcon: false,
    svgStyle: undefined,
  },
)

const shouldShowJapaneseLogo = getHyphenatedCurrentLocale() === 'ja'

const svgViewBox = computed<string>(() => {
  if (shouldShowJapaneseLogo) return '0 0 301 81'
  return '0 0 72 20'
})

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
}>()

function click(event: MouseEvent): void {
  if (props.hrefMode !== OzBaseButtonHrefMode.Default) event.preventDefault()
  if (props.hrefMode === OzBaseButtonHrefMode.HistoryPushState) {
    // Store the url in the history state so you can read it later
    window?.history?.pushState({ url: props.href }, '', props.href)
  }

  /**
   * Click event
   * @property {MouseEvent} event click event
   */
  emit('click', event)
}
</script>

<script lang="ts">
enum ColorScheme {
  Text100 = 'Text100',
  Text200 = 'Text200',
  Text300 = 'Text300',
}

enum SizePreset {
  W48px = 'W48px',
  W64px = 'W64px',
  W72px = 'W72px',
  W86px = 'W86px',
  W96px = 'W96px',
  W128px = 'W128px',
  W160px = 'W160px',
}

export { OzBaseButtonHrefMode as OzPadletLogoHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
export { ColorScheme as OzPadletLogoColorScheme, SizePreset as OzPadletLogoSizePreset }
export default {}
</script>

<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <a
    :class="[
      'flex',
      'items-center',
      'group',

      // Focus styles
      'padlet-logo',
      sizePreset === SizePreset.W48px && 'padlet-logo-w-12',
      sizePreset === SizePreset.W64px && 'padlet-logo-w-16',
      sizePreset === SizePreset.W72px && 'padlet-logo-w-18',
      sizePreset === SizePreset.W86px && 'padlet-logo-w-21.5',
      sizePreset === SizePreset.W128px && 'padlet-logo-w-32',
      sizePreset === SizePreset.W160px && 'padlet-logo-w-40',
      {
        'padlet-logo-light': darkMode === false,
        'padlet-logo-dark': darkMode === true,
        'padlet-logo-auto': darkMode === 'auto',
      },
    ]"
    :href="href"
    data-testid="padletLogo"
    @click="click"
  >
    <svg
      :viewBox="svgViewBox"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      :class="[
        'block',

        /**
         * COLOR SCHEMES
         */

        // Text
        'fill-current',
        colorScheme !== ColorScheme.Text200 && {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
        colorScheme === ColorScheme.Text200 && {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },

        // Background
        'hover-hover:group-hover:opacity-100',
        'transition-opacity',
        'motion-reduce:transition-none',
        colorScheme === ColorScheme.Text300 && {
          'opacity-[.38]': darkMode === false,
          'opacity-[.50]': darkMode === true,
          'opacity-[.38] dark:opacity-[.50]': darkMode === 'auto',
        },

        /**
         * SIZE PRESETS
         */
        sizePreset === SizePreset.W48px && 'w-[43.2px]',
        sizePreset === SizePreset.W64px && 'w-[57.6px]',
        sizePreset === SizePreset.W72px && 'w-18',
        sizePreset === SizePreset.W86px && 'w-[86.4px]',
        sizePreset === SizePreset.W128px && 'w-[115.2px]',
        sizePreset === SizePreset.W160px && 'w-[144px]',
      ]"
      :style="svgStyle"
    >
      <title>{{ __('Padlet logo') }}</title>
      <path
        v-if="shouldShowJapaneseLogo"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.5 14C57.5 17.866 54.366 21 50.5 21C46.634 21 43.5 17.866 43.5 14C43.5 10.134 46.634 7 50.5 7C54.366 7 57.5 10.134 57.5 14ZM64.5 14C64.5 21.732 58.232 28 50.5 28C42.768 28 36.5 21.732 36.5 14C36.5 6.26801 42.768 0 50.5 0C58.232 0 64.5 6.26801 64.5 14ZM116.81 24.947L109.11 9.44695L118.961 4.55304L126.661 20.053L116.81 24.947ZM77.5 80V6H91.5V37.9926L125.897 53.6274L120.103 66.3726L91.5 53.371V80H77.5ZM138 73V6H152V64.3753C153.052 63.7979 154.135 63.0231 155.254 61.9873C159.353 58.1946 164.446 50.4231 169.303 34.4621L182.697 38.5379C177.512 55.5769 171.471 66.0554 164.763 72.2627C157.712 78.7877 150.578 80 145 80H138V73ZM94.5213 12.947L102.221 28.447L112.073 23.553L104.373 8.05305L94.5213 12.947ZM248 80V6H262V37.8121L300.165 53.5273L294.835 66.4727L262 52.9526V80H248ZM227.455 24.4114L227.517 23.5138L241.483 24.4862C241.446 25.0211 241.409 25.5683 241.371 26.1267C240.778 34.9069 239.998 46.4623 234.977 56.6419C229.296 68.1584 218.654 77.2192 199.834 80.8718L197.166 67.1282C212.177 64.2151 218.884 57.6193 222.421 50.449C226.178 42.8309 226.802 33.8281 227.455 24.4114ZM203.679 25.4552L208.679 45.4552L220.321 42.5448L215.321 22.5448L203.679 25.4552ZM192.688 46.9902L187.688 27.4903L199.312 24.5097L204.312 44.0098L192.688 46.9902ZM18.0194 11.4791C15.6055 43.8255 8.96497 57.834 0.909851 72.0489L13.0902 78.9511C22.035 63.166 29.3945 47.1745 31.9806 12.5209L18.0194 11.4791ZM54.6861 78.6033C51.5545 65.2939 45.1621 44.4535 42.3345 35.638L55.6655 31.362C58.5046 40.2132 65.0455 61.5061 68.3139 75.3967L54.6861 78.6033Z"
      />
      <path
        v-else
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.2 14.7984C11.1765 14.7984 14.4 11.5749 14.4 7.59844C14.4 3.62199 11.1765 0.398438 7.2 0.398438C7.13312 0.398438 7.06644 0.39935 7 0.401162V0.398438H3.6H2.4H0V7.59844V19.5984H3.6V14.7984H7V14.7957C7.06644 14.7975 7.13312 14.7984 7.2 14.7984ZM7.19992 11.1984H7.2C9.16019 11.1984 10.7492 9.58664 10.7492 7.59844C10.7492 5.61024 9.16019 3.99848 7.2 3.99844H7.19992H3.6V11.1984H7.19992ZM25.2 5.19844H28.8V19.5984H25.2V18.6353C24.141 19.2479 22.9114 19.5985 21.6 19.5985C17.6235 19.5985 14.4 16.3749 14.4 12.3985C14.4 8.42204 17.6235 5.19849 21.6 5.19849C22.9114 5.19849 24.141 5.54911 25.2 6.16172V5.19844ZM21.6 8.79844C23.5882 8.79844 25.2 10.4102 25.2 12.3984C25.2 14.3867 23.5882 15.9984 21.6 15.9984C19.6118 15.9984 18 14.3867 18 12.3984C18 10.4102 19.6118 8.79844 21.6 8.79844ZM44 0.398438H40.4V6.16167C39.3409 5.54906 38.1114 5.19844 36.8 5.19844C32.8235 5.19844 29.6 8.42199 29.6 12.3984C29.6 16.3749 32.8235 19.5984 36.8 19.5984C38.1114 19.5984 39.3409 19.2478 40.4 18.6352V19.5984H44V12.3984V0.398438ZM40.4 12.4169C40.39 14.3966 38.7821 15.9984 36.8 15.9984C34.8118 15.9984 33.2 14.3866 33.2 12.3984C33.2 10.4102 34.8118 8.79839 36.8 8.79839C38.7821 8.79839 40.39 10.4002 40.4 12.3799V12.4169ZM49 0.398438H45.4V19.5984H49V0.398438ZM64.33 13.6088C63.9644 15.7426 62.6474 17.7036 60.6252 18.7339C56.9257 20.6189 52.5901 19.2102 50.8645 15.8236C48.9795 12.1241 50.3883 7.78843 54.0878 5.90344C57.4743 4.17789 61.81 5.58664 63.695 9.28615C63.884 9.65698 64.0353 10.0392 64.1506 10.4281L60.5086 12.2838L60.5088 12.2838L54.882 15.1508C55.9628 16.051 57.5435 16.2637 58.9871 15.5282C60.1296 14.9461 60.8166 13.695 60.8047 12.3864L64.33 13.6088ZM53.6108 12.656L59.5229 9.64364C58.4565 8.73604 56.9329 8.49409 55.7184 9.11291C54.2747 9.84852 53.5176 11.2525 53.6108 12.656ZM65.4 0.398438H68.9985V6.39844H71.7984V9.99844H68.9985V19.5984H65.4V0.398438Z"
      />
    </svg>

    <!-- Padlet crane icon -->
    <svg
      v-if="xCraneIcon"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      data-testid="padletLogoCraneIcon"
      :class="[
        'crane-icon',
        'group-active:transition-transform group-active:scale-[1.2]',

        /**
         * SIZE PRESETS
         */
        sizePreset === SizePreset.W48px && 'w-3',
        sizePreset === SizePreset.W64px && 'w-4',
        sizePreset === SizePreset.W72px && 'w-[25px]',
        sizePreset === SizePreset.W86px && 'w-7.5',
        sizePreset === SizePreset.W128px && 'w-8',
        sizePreset === SizePreset.W160px && 'w-10',

        // Spacing
        sizePreset === SizePreset.W48px && 'ms-1.5',
        sizePreset === SizePreset.W64px && 'ms-2',
        sizePreset === SizePreset.W72px && 'ms-1.5',
        sizePreset === SizePreset.W86px && 'ms-1.75',
        sizePreset === SizePreset.W128px && 'ms-3',
        sizePreset === SizePreset.W160px && 'ms-4',
      ]"
    >
      <title>{{ __('Padlet crane') }}</title>
      <path
        d="M24.0622 9.70996L22.6421 7.114C22.6421 7.114 22.64 7.10974 22.6393 7.10761C22.6336 7.09555 22.6272 7.0842 22.6209 7.07213C22.6045 7.04304 22.5868 7.01536 22.5662 6.9891C22.5279 6.93588 22.4803 6.88762 22.4292 6.84717C22.4037 6.82659 22.3767 6.80885 22.349 6.79252C22.3065 6.76698 22.2625 6.7464 22.2163 6.73078C22.2043 6.72652 22.1936 6.72298 22.183 6.72014C22.1149 6.69956 22.0439 6.68891 21.9715 6.68891H19.4039C19.3032 6.68891 19.2031 6.7102 19.1108 6.74994L15.955 8.11108L13.0063 1.26068C12.7884 0.75327 12.2633 0.406953 11.7019 0.398438H11.6792C11.1179 0.406953 10.5934 0.75327 10.3749 1.25926L7.41982 8.12457L2.26623 5.28377C2.09378 5.18868 1.90146 5.13829 1.70914 5.13829C1.30179 5.13829 0.920701 5.35971 0.713478 5.71596C0.506966 6.0715 0.506966 6.49801 0.713478 6.85781C0.714188 6.86136 0.716317 6.8642 0.719155 6.86633L0.973925 7.29781L7.5064 18.6979C7.51278 18.7099 7.51988 18.722 7.52698 18.7334L7.53194 18.7412C7.84633 19.2642 8.41406 19.5984 9.05489 19.5984C9.18759 19.5984 9.3203 19.5835 9.44946 19.5544L11.6906 19.0449L13.931 19.5544C14.0602 19.5835 14.1943 19.5984 14.327 19.5984C15.0232 19.5984 15.6342 19.2039 15.9252 18.6006L17.7426 15.4284L20.9879 10.3416C21.0042 10.3501 21.0198 10.3586 21.0362 10.3664L22.6925 11.1541C22.8359 11.2223 22.9877 11.257 23.1439 11.257C23.5108 11.257 23.855 11.0605 24.0444 10.7418C24.2339 10.4225 24.2403 10.0364 24.0615 9.70854L24.0622 9.70996ZM12.2413 1.409C12.2768 1.45158 12.3052 1.49984 12.3279 1.55235C12.2988 1.50197 12.2704 1.45442 12.2413 1.409Z"
        fill="black"
      />
      <path
        d="M23.4132 10.0622C23.5559 10.3233 23.2805 10.6143 23.0116 10.4866L21.3545 9.69955L21.9719 7.42578L23.4125 10.0622H23.4132Z"
        fill="#CED0D4"
      />
      <path
        d="M12.328 1.55187L16.9231 12.2274L12.0576 12.9228L11.6914 1.13672C11.949 1.14027 12.2201 1.30065 12.3287 1.55187H12.328Z"
        fill="#FFD54F"
      />
      <path
        d="M16.9231 12.2266L15.3398 18.0515C15.2128 18.6107 14.6557 18.9613 14.0958 18.8343L11.6914 18.2885L12.0611 13.6615L16.9238 12.2273L16.9231 12.2266Z"
        fill="#FF833E"
      />
      <path
        d="M11.6904 1.13672L11.3206 12.9228L6.45801 12.2274L11.0531 1.55187C11.1617 1.30065 11.4328 1.14027 11.6904 1.13672Z"
        fill="#53E047"
      />
      <path
        d="M11.3209 13.6615L11.6906 18.2885L9.28629 18.8343C8.72637 18.9613 8.16928 18.6107 8.04154 18.0515L6.45898 12.2266L11.3216 13.6608L11.3209 13.6615Z"
        fill="#9466E8"
      />
      <path
        d="M17.6018 11.9336C17.6678 12.0869 17.6799 12.2579 17.6359 12.419L16.7829 15.5578L21.9712 7.42578H19.403L16.2471 8.78763L17.6011 11.9336H17.6018Z"
        fill="#FF4081"
      />
      <path
        d="M5.74524 12.4195C5.70124 12.2585 5.71331 12.0874 5.7793 11.9341L7.12625 8.80451L1.90879 5.92823C1.54615 5.72811 1.14945 6.1312 1.35525 6.49029L6.63871 15.7074L5.74524 12.4195Z"
        fill="#3992DE"
      />
    </svg>
  </a>
</template>

<style lang="postcss" scoped>
.padlet-logo:focus-visible {
  position: relative;
  outline: none;
}

.padlet-logo:focus-visible::after {
  content: '';
  position: absolute;
  pointer-events: none;
  outline-style: solid;
  inset-block: calc(var(--logo-height) / 2 * -1);
  inset-inline: calc(var(--logo-width) / 6 * -1);
}

.padlet-logo-w-12:focus-visible::after {
  --logo-height: 0.75rem;
  --logo-width: 3rem;
  outline-width: 2px;
  border-radius: 0.75rem;
}

.padlet-logo-w-16:focus-visible::after {
  --logo-height: 1rem;
  --logo-width: 4rem;
  outline-width: 2px;
  border-radius: 0.75rem;
}

.padlet-logo-w-18:focus-visible::after {
  --logo-height: 1rem;
  --logo-width: 4rem;
  outline-width: 2px;
  border-radius: 0.75rem;
}

.padlet-logo-w-21\.5:focus-visible::after {
  --logo-height: 1.5rem;
  --logo-width: 6rem;
  outline-width: 3px;
  border-radius: 1rem;
}

.padlet-logo-w-32:focus-visible::after {
  --logo-height: 2rem;
  --logo-width: 8rem;
  outline-width: 4px;
  border-radius: 1.75rem;
}

.padlet-logo-w-40:focus-visible::after {
  --logo-height: 2.5rem;
  --logo-width: 10rem;
  outline-width: 4px;
  border-radius: 1.75rem;
}

.padlet-logo-light:focus-visible::after {
  outline-color: theme('colors.grape.500');
}

.padlet-logo-dark:focus-visible::after {
  outline-color: theme('colors.canary.500');
}

.padlet-logo-auto:focus-visible::after {
  outline-color: theme('colors.grape.500');

  @media (prefers-color-scheme: dark) {
    outline-color: theme('colors.canary.500');
  }
}

.padlet-logo:hover:not(:active) .crane-icon {
  animation: wiggle;
  animation-duration: 600ms;
  animation-fill-mode: forward;
  transition: all 100ms ease-out;
}

@keyframes wiggle {
  0% {
    transform: rotate(8deg);
    transition: all 200ms ease-out;
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(8deg);
  }
  75% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
